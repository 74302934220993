export enum RouteName {
  HOME = 'home',
  DASHBOARD = 'dashboard',
  SIGNUP = 'signup',
  SIGNIN = 'signin',
  INTEGRATIONS = 'integrations',
  ONBOARDING = 'onboarding',
  ARTICLE = 'article',

  // Admin
  ADMIN_DASHBOARD = 'admin-dashboard',
}

import './assets/main.css'

import { createApp, type ComponentPublicInstance } from 'vue'
import App from './App.vue'
import router from './router'

import * as Sentry from '@sentry/vue'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import './firebase'
import posthog from './posthog'

let mountedApp: ComponentPublicInstance | null = null

/*
We wait for Firebase to fire first auth event before creating the app
so that our route middleware can check the user's authentication status
*/
onAuthStateChanged(getAuth(), () => {
  if (!mountedApp) {
    const app = createApp(App)

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost', /^https:\/\/cubius\.com\/api/],
      replaysSessionSampleRate: import.meta.env.MODE === 'development' ? 1 : 0.1,
      replaysOnErrorSampleRate: 1.0,
    })

    mountedApp = app.use(router).use(posthog).mount('#app')
  }
})

import { getAuth } from 'firebase/auth'
import { doc, getDocFromServer, getFirestore } from 'firebase/firestore'
import { createRouter, createWebHistory } from 'vue-router'
import { adminRoutes } from './adminRoutes'
import { RouteName } from './routeNames'

export { RouteName } from './routeNames'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/admin',
      children: adminRoutes,
    },
    {
      path: '/',
      name: RouteName.HOME,
      component: () => import('@/views/HomeView.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/signup',
      name: RouteName.SIGNUP,
      component: () => import('@/views/SignupView.vue'),
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: '/signin',
      name: RouteName.SIGNIN,
      component: () => import('@/views/SigninView.vue'),
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: '/onboarding',
      name: RouteName.ONBOARDING,
      component: () => import('@/views/OnboardingView.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/article',
      name: RouteName.ARTICLE,
      component: () => import('@/views/ArticleView.vue'),
      meta: {
        requiresAuth: true,
      },
    },
  ],
})

router.beforeEach(async (to) => {
  if (!to.meta.requiresAuth && !to.meta.requiresGuest) return true

  const currentUser = getAuth().currentUser
  if (!currentUser && to.meta.requiresAuth) {
    return {
      name: RouteName.SIGNIN,
      query: {
        redirect: to.fullPath,
        ...to.query,
      },
    }
  }

  if (currentUser && to.meta.requiresGuest) {
    return {
      name: RouteName.HOME,
    }
  }

  return true
})

router.beforeEach(async (to) => {
  if (!to.meta.requiresAdmin) return true

  const currentUser = getAuth().currentUser
  if (!currentUser) throw new Error('Reached admin middleware without user')

  const idTokenResult = await currentUser.getIdTokenResult()
  if (!idTokenResult.claims.isAdmin) {
    return { name: RouteName.HOME }
  }

  return true
})

router.beforeEach(async (to) => {
  if (!to.meta.requiresAuth) return true

  const currentUser = getAuth().currentUser
  if (!currentUser) throw new Error('Reached onboarding middleware without user')

  const db = getFirestore()
  const userDoc = await getDocFromServer(doc(db, `profiles/${currentUser.uid}`))
  const userData = userDoc.data()
  const onboardingComplete = userDoc.exists() && !!userData?.onboarding?.interests?.length

  if (!onboardingComplete && to.name !== RouteName.ONBOARDING) {
    return { name: RouteName.ONBOARDING }
  }

  return true
})

export default router

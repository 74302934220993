import { initializeApp } from 'firebase/app'
import { ReCaptchaEnterpriseProvider, initializeAppCheck } from 'firebase/app-check'
import { getAuth } from 'firebase/auth'

const firebaseConfig = JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG)

const firebaseApp = initializeApp(firebaseConfig)

if (import.meta.env.MODE === 'development') {
  const { connectAuthEmulator, getAuth } = await import('firebase/auth')
  const { connectFirestoreEmulator, getFirestore } = await import('firebase/firestore')
  const { connectFunctionsEmulator, getFunctions } = await import('firebase/functions')
  const { connectStorageEmulator, getStorage } = await import('firebase/storage')

  connectFirestoreEmulator(getFirestore(), 'localhost', 8080)
  connectAuthEmulator(getAuth(), 'http://localhost:9099', { disableWarnings: true })
  connectStorageEmulator(getStorage(), 'localhost', 9199)
  connectFunctionsEmulator(getFunctions(), 'localhost', 5001)
} else {
  initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(import.meta.env.VITE_RECAPTCHA_KEY),
    isTokenAutoRefreshEnabled: true,
  })
}

const auth = getAuth(firebaseApp)

export { auth, firebaseApp }

import type { RouteRecordRaw } from 'vue-router'
import { RouteName } from './routeNames'

export const adminRoutes: RouteRecordRaw[] = [
  {
    name: RouteName.ADMIN_DASHBOARD,
    path: 'dashboard',
    component: () => import('@/admin/DashboardView.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
]

<template>
  <div class="bg-background min-h-screen min-w-full pb-24">
    <div class="fixed bottom-4 right-4 z-[10000]">
      <DropdownMenu>
        <DropdownMenuTrigger as-child>
          <button class="">
            <CircleHelp class="text-foreground m-3 h-6 w-6" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem :as="RouterLink" :to="{ name: RouteName.HOME }">Help</DropdownMenuItem>
          <DropdownMenuItem :as="RouterLink" :to="{ name: RouteName.HOME }">Feedback</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>

    <RouterView class="max-h-screen overflow-y-auto" />
  </div>
</template>

<script setup lang="ts">
import { CircleHelp } from 'lucide-vue-next'
import { RouterLink, RouterView, useRoute } from 'vue-router'
import DropdownMenu from './components/ui/dropdown-menu/DropdownMenu.vue'
import DropdownMenuContent from './components/ui/dropdown-menu/DropdownMenuContent.vue'
import DropdownMenuItem from './components/ui/dropdown-menu/DropdownMenuItem.vue'
import DropdownMenuTrigger from './components/ui/dropdown-menu/DropdownMenuTrigger.vue'
import { RouteName } from './router'

const route = useRoute()
</script>
